@font-face {font-family: 'trajan_reg';
  src: 
  url('./fonts/TrajanPro-Bold.ttf') format('truetype'),
  url('./fonts/TrajanPro-Bold.woff') format('woff'),
  url('./fonts/TrajanPro-Bold.otf') format('opentype')
  ;}

  :root {
    --main-hashed-bg-color: #3f3b3b;
    --header-first-color: white;
    --header-second-color: #e4451c;
    --header-third-color: #481d24;
    --programs-color: #3f3b3b;
    --channels-page-card-background-color: #333030;
    --channels-page-card-title-text-color: white;
    --channels-page-card-categories-text-color: white;
    --channels-page-card-categories-background-color: #333030;
    --channels-page-card-text-color: white;
  }


  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
  }

  html, body {
    width: 100%;
    height: 100%;
  }

  body {
    background-color: #333030;
    scroll-behavior: smooth;

  }

  .html {
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
  }

  
  .flexbox-show-div {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #3f3b3b;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-height: 109px;
    max-height: 111px;

    /*border-right: 1px solid black;*/
   
    transition: padding-right  .3s ease-in;
    transition: width  .2s ease-in;
    z-index: 1;
   
   

  }

   .flexbox-show-div:hover{

      filter: brightness(85%);
      
    }


  .flexbox-row-div {
    display: flex;
    border-bottom: 1px solid black;
    flex-wrap: nowrap;
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    height: 100%;
    width: 5000px;
    min-height: 110px;
    max-height: 111px;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: linear-gradient(135deg, #000 3.75%, var(--main-hashed-bg-color) 3.75%, var(--main-hashed-bg-color) 46.25%, #000 46.25%, #000 53.75%, var(--main-hashed-bg-color) 53.75%, var(--main-hashed-bg-color) 96.25%, #000 96.25%);
    background-size: 5px 5px;
    background-position: 50px 50px;
  }

  .flexbox-mobile-app-rows-container{
    display: flex;
    position: relative;
    top: 19%;
    margin: 0;
    padding: 0;
  }

  .flexbox-row-div-mobile {
    display: flex;
    border-bottom: 1px solid black;
    position: relative;
    top: 22%;
    flex-wrap: nowrap;
    padding: 0px 0px 0px 0px;
    flex-direction: row;
    height: 100%;
    width: 100%;
    min-height: 15%;
    max-height: 15%;
    background-color: #333030;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: linear-gradient(135deg, #000 3.75%, #3f3b3b 3.75%, #3f3b3b 46.25%, #000 46.25%, #000 53.75%, #3f3b3b 53.75%, #3f3b3b 96.25%, #000 96.25%);
    background-size: 5px 5px;
    background-position: 50px 50px;
  }




  .activeHover:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 200px;
    padding-right: 100px;
    text-align: center;
  }
  

  .activeDay{
    color: #DA6437;
    background-color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    color: #DA6437;
    text-decoration: underline;
  }


.loadingDataDiv {
  position: absolute;
  top: 190px;
  left: 160px;
  width: 100%;
  height: 100%;
  background-color: #3f3b3b;
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  opacity: 0.99;
  z-index: 5;
  -webkit-transition: opacity 0ms,visibility 0ms;
  transition:  opacity 0ms,visibility 0ms;

}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 20% 0 0 45%;
  -webkit-transition: visibility 0ms;
  transition:  visibility 0ms;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 164px;
  height: 164px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.option {
  background-color: #FFFFFF;
  padding: 100px;
}

.option-selected {
    background-color: var(--header-third-color);
    border: 1px solid var(--header-third-color);
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: underline;
    font-weight: bold;
    width: auto;

}
.option-selected > a {
    color: white;

}





.dayLink:hover{
background-color: #481d24;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-decoration: underline;
  height:100%;


}

.dayLink {
  display: flex;
  flex-direction:column;
  height:100%;
  width: 120px;
  align-items: center;
  justify-content: center;

}


.schedule {
  display: inline-block;
  height: auto;
  text-align: center;
  
}


.language{
  z-index: 20;
}

.operators{
  z-index: 20;
}


/*******/


.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
}

.dayN {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 5vmin;
  text-align: center;
  height: 100%;

}





/*MODAL */


#myBtn {
  position: absolute;
  top:0px;
  right: 0px;
  width: 100px;
  height: 50px;

  z-index: 15;
}

.myBtn{
  z-index: 20;

}
.myBtn:hover{
  border-bottom: 1px solid white;
  cursor: pointer;
}
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
 /* padding-top: 100px; /* Location of the box */
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  z-index: 15;
}


/* Modal Content */
.modal-content {
  display: flex;
  background-color: var(--channels-page-card-background-color);
  margin: auto;
 /* padding: 20px;*/
  border: 1px solid #888;
  width: 80%;
  flex-direction: column;
  padding-bottom: 5%;
  height: auto;
}


.modal-thumbnail-container{
  display: flex;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  position: relative;

}

.modal-thumbnail{
  height: 100%;
  width: 100%;
}

.modal-description-container{
  display: flex;
  background-color: var(--channels-page-card-background-color);
  width: 100%;
  height: 80%;
  flex-direction: column;
  align-items: center;
}

.modal-description {
  display: flex;
  background-color: var(--header-second-color);;
  width: 90%;
  height: 100%;
  flex-direction: column;
  padding-bottom: 5%;
  /*arabic*/
 /* align-items: flex-end;*/

}

.modal-title {
  margin-top: 10%;
  margin-left: 5%;
  margin-right: 5%;
  color: var(--channels-page-card-title-text-color);
  font-weight: bold;
  height: auto;
  width: auto;
  font-size: 5vmin;
}

.modal-categories-list {
  display: flex;
  text-decoration: none;
  list-style: none;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 1%;
  flex-wrap: wrap;
  width: auto;
  height: auto;
}

.modal-categories-list > li{
  background-color: var(--channels-page-card-categories-background-color);
  color: var(--channels-page-card-categories-text-color);
  font-weight: bold;
  width: auto;
  padding: 1vmin;
  margin-top: 1%;
  margin-right: 2vmin;
  border-radius: 4px;
  font-size: 2vmin;
  font-family: trajan_reg, serif;


}

.modal-schedule {
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
  height: auto;
  width: auto;
  color:  var(--channels-page-card-text-color);

  font-size: 4vmin;

}

.modal-length{

  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
  color: var(--channels-page-card-text-color);

  height: auto;
  width: auto;
  font-size: 4vmin;

}

.modal-synopsis {

  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
  color: var(--channels-page-card-text-color);
  height: auto;
  width: auto;
  font-size: 4vmin;
  /*arabic*/
  /*text-align: end;*/

}


/* The Close Button */
.close {
  position: absolute;
display: flex;
  color: white;
  /*float: right;*/
  justify-content: center;
  align-self: flex-start;
  font-size: 7vmin;
  font-weight: bold;
  width: 10%;
  height: 5%;
  text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
  z-index: 25;
  /* box-shadow: #000;*/
  /*drop shadow on the close*/
}




.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}




/* EPG ADMINISTRATION */ 
.replay-box{
  background-color: green;
}
.replay-box:hover{
  box-shadow: 0 0 51px rgb(48, 191, 19); 
}


.clients-box{
  background-color: blue;
}
.clients-box:hover{
  box-shadow: 0 0 51px rgb(17, 132, 255); 
}

.new-programs-box{
  background-color: purple;
}
.new-programs-box:hover{
  box-shadow: 0 0 51px rgb(176, 32, 248); 
}

.profile-box{
  background-color: orange;
}
.profile-box:hover{
  box-shadow: 0 0 51px rgb(252, 164, 0); 
}



.updateDiv{
  display: flex;
  border: 1px solid black;
  margin-top: 2%;
  height: auto;
  justify-content: space-between;
}

.updateBtn{
  display: flex;
  background-color: purple;
  height: 100%;
  width: auto;
  font-size: 3vh;
  border-radius: 4px;
  color: white;
}

.addChannels{
  display: flex;
  background-color: orange;
  height: 100%;
  width: auto;
  font-size: 3vh;
  border-radius: 4px;
  color: white;
}

.channelInput{
display: flex;
text-align: center;
height: 5%;
width: 80%;
font-size: 2vh;
font-weight: bold;
justify-content: center;
margin-top: 5%;
background-color: grey;
}

.closeChannelInput{
  display: flex;
  height: 5%;
  width: 20%;
  font-size: 2vh;
  font-weight: bold;
  justify-content: center;
  margin-top: 5%;
  background-color: red;
}
